<template>
  <div class="list-card">
    <b-card-sub-title sub-title-text-variant="primary">
      {{title}}
    </b-card-sub-title>

    <ol :class="variant ? variant : ''">
      <li v-for="(item, index) in items" :key="index">
        <div v-if="variant == 'bubble'" class="number-list-block">
          <div class="number-item">{{index + 1}}</div>
          <div v-if="index + 1 !== items.length" class="horizontal-line"></div> 
        </div>
        <span class="list-text">{{item}}</span>
      </li>
    </ol>

    <b-card-text v-if="prompt" class="text-muted" style="font-weight: 400; font-size: 16px; line-height: 24px;">
      {{prompt}}
    </b-card-text>

    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {
    title: String,
    items: Array,
    variant: {
      type: String,
      default: null
    },
    prompt: {
      type: String,
      default: null
    }
  }
};
</script>

<style lang="scss">
  .list-card {
    padding-bottom: 28px;
    li {
      padding-bottom: 14px;
    }
    ol {
      padding-left: 20px;
    }
    .bubble {
      padding-left: 0px;
      line-height: 54px;
      li {
        &::marker {
          content: "";
        }
        align-items: center;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        .horizontal-line {
          width: 1px;
          border-left: dashed 1px #D4DBE3;
          height: 40px;
          position: absolute;
          top: 30px;
          z-index: 1;
        }
        .list-text {
          margin-left: 16px;
        }
        .number-item {
          padding: 5px 14px;
          border-radius: 50px;
          background-color: #f5f8fb;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
          z-index: 2;
        }
        .number-list-block {
          display: flex;
          flex-direction: column;
          position: relative;
          align-content: center;
          align-items: center;
          justify-content: flex-end;
        }
      }
    }
  }
  .dark-layout .list-card {
    .bubble {
      .number-item {
        color: #000;
      }
    }
  }
</style>
