<template>
  <b-overlay
    :show="is_loading"
    spinner-variant="primary"
    variant="transparent"
    style="min-height: 50vh"
  >
    <div v-if="offer">
      <showAt breakpoint="large">
        <desktop :offer="offer" />
      </showAt>
      <showAt breakpoint="medium">
        <tablet :offer="offer" />
      </showAt>
      <showAt breakpoint="small">
        <mobile :offer="offer" />
      </showAt>
    </div>
  </b-overlay>
</template>

<script>
import Offers from "@/modules/offers/";

import logo from "@/assets/images/site/demo/logo_card.png";
import img from "@/assets/images/site/demo/offer_header_img.png";

import desktop from "./views/desktop";
import { findNumbers } from "libphonenumber-js";
import tablet from "./views/tablet";
import mobile from "./views/mobile";

export default {
  data() {
    return {
      Offers,
      offer: {},
      is_loading: false,
    };
  },
  methods: {},
  components: {
    desktop,
    tablet,
    mobile,
  },
  watch: {},
  mounted() {
    this.is_loading = true;
    Offers.get(this.$route.params.item, (offer) => {
      (this.offer = offer), (this.is_loading = false);
    });
  },
};
</script>

