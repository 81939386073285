var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"claim-content"},[(!_vm.link)?_c('b-card-body',{staticStyle:{"padding":"0"}},[_c('b-card-title',[_vm._v("Укажите данные нового клиента")]),_c('validation-observer',{ref:"client"},[_c('b-form',{staticClass:"client-form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Фамилия","label-for":"last_name"}},[_c('validation-provider',{attrs:{"name":"Фамилия","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Введите фамилию"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3916715999)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Имя","label-for":"first_name"}},[_c('validation-provider',{attrs:{"name":"Имя","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Введите имя"},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4192719865)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Отчество","label-for":"middle_name"}},[_c('validation-provider',{attrs:{"name":"Отчество","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Введите отчество"},model:{value:(_vm.form.middle_name),callback:function ($$v) {_vm.$set(_vm.form, "middle_name", $$v)},expression:"form.middle_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3015601092)})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Телефон","label-for":"phone_number"}},[_c('validation-provider',{attrs:{"name":"Телефон","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{directives:[{name:"mask",rawName:"v-mask",value:('+7 (###) ### ##-##'),expression:"'+7 (###) ### ##-##'"}],attrs:{"name":"phone_number","placeholder":'+7 (###) ### ##-##'},model:{value:(_vm.form.phone_number),callback:function ($$v) {_vm.$set(_vm.form, "phone_number", $$v)},expression:"form.phone_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2058956870)})],1)],1),_c('b-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"ml-auto w-100",staticStyle:{"font-size":"16px","border-radius":"16px"},attrs:{"type":"submit","pill":"","variant":"primary","disabled":_vm.is_loading},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._v(" Далее ")])],1)],1)],1)],1)],1):_c('b-card-body',{staticStyle:{"padding":"0"}},[_c('alert-card-view',{attrs:{"text":"Компания приступит к рассмотрению заявки после того, как вы полностью заполните ее на сайте партнера.","warning":true}}),_c('b-row',[_c('b-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"ml-auto w-100",staticStyle:{"font-size":"16px","border-radius":"16px"},attrs:{"href":_vm.link,"target":"_blank","pill":"","variant":"primary"}},[_vm._v(" Завершение оформления на сайте партнера ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }