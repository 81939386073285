<template>
  <div class="claim-content">
    <b-card-body style="padding: 0">
      <b-card-title>Оставьте заявку на одобрение работы</b-card-title>

      <validation-observer ref="form">
        <b-form class="client-form">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Способ привлечения клиентов на оффер"
                label-for="attraction"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Attraction"
                  rules="required"
                >
                  <b-form-input placeholder="" v-model="form.attraction" />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Источник трафика (ссылка на ресурс)"
                label-for="source"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Source"
                  rules="required|url"
                >
                  <b-form-input
                    placeholder="https://..."
                    v-model="form.source"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Прогнозируемое кол-во заявок в месяц"
                label-for="quantity"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Projected quantity"
                  rules="required"
                >
                  <b-form-input
                    placeholder="Например, 150 заявок"
                    v-model="form.projected_quantity"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Креатив размещения (ссылка на источник)"
                label-for="placement_creative"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Placement creative"
                  rules="required|url"
                >
                  <b-form-input
                    v-model="form.placement_creative"
                    name="Placement creative"
                    placeholder="https://..."
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="d-flex">
              <b-button
                type="submit"
                @click.prevent="submit"
                class="ml-auto w-100"
                pill
                variant="primary"
                :disabled="is_loading"
                style="font-size: 16px; border-radius: 16px"
              >
                Отправить
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card-body>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import alertCardView from "@/components/AlertCard";

export default {
  data() {
    return {
      is_loading: false,
      is_confirm: false,
      type: 1,
      form: {
        attraction: "",
        source: "",
        projected_quantity: "",
        placement_creative: "",
      },
    
    };
  },
  props: {
    offer: Number,
  },
  methods: {
    submit() {
      this.$refs.form.validate().then((success) => {
        if (!success) return;
        this.is_loading = true;
        this.$request
          .post("offers.requestAccess", {
            offer: this.offer,
            formData: {
              type: this.type,
              data: this.form,
            },
          })
          .then((rsp) => {
            this.is_loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                text: 'Заявка успешно отправлена',
                icon: 'CheckCircleIcon',
                variant: 'success',
              }
            });
            this.$emit("afterSendApplicationOpening");
          }).catch(err => {
            this.is_loading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ошибка сервера',
                text: 'Повторите попытку позже или обратитесь в службу поддержки',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              }
            });
          });
      });
    },
  },

  computed: {},

  mounted() {},

  watch: {},

  components: {
    ValidationProvider,
    ValidationObserver,
    alertCardView,
  },
};
</script>

<style lang="scss" scoped></style>
