var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"claim-content"},[_c('b-card-body',{staticStyle:{"padding":"0"}},[_c('b-card-title',[_vm._v("Оставьте заявку на одобрение работы")]),_c('validation-observer',{ref:"form"},[_c('b-form',{staticClass:"client-form"},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Способ привлечения клиентов на оффер","label-for":"attraction"}},[_c('validation-provider',{attrs:{"name":"Attraction","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":""},model:{value:(_vm.form.attraction),callback:function ($$v) {_vm.$set(_vm.form, "attraction", $$v)},expression:"form.attraction"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Источник трафика (ссылка на ресурс)","label-for":"source"}},[_c('validation-provider',{attrs:{"name":"Source","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"https://..."},model:{value:(_vm.form.source),callback:function ($$v) {_vm.$set(_vm.form, "source", $$v)},expression:"form.source"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Прогнозируемое кол-во заявок в месяц","label-for":"quantity"}},[_c('validation-provider',{attrs:{"name":"Projected quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Например, 150 заявок"},model:{value:(_vm.form.projected_quantity),callback:function ($$v) {_vm.$set(_vm.form, "projected_quantity", $$v)},expression:"form.projected_quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Креатив размещения (ссылка на источник)","label-for":"placement_creative"}},[_c('validation-provider',{attrs:{"name":"Placement creative","rules":"required|url"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"Placement creative","placeholder":"https://..."},model:{value:(_vm.form.placement_creative),callback:function ($$v) {_vm.$set(_vm.form, "placement_creative", $$v)},expression:"form.placement_creative"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"d-flex",attrs:{"cols":"12"}},[_c('b-button',{staticClass:"ml-auto w-100",staticStyle:{"font-size":"16px","border-radius":"16px"},attrs:{"type":"submit","pill":"","variant":"primary","disabled":_vm.is_loading},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._v(" Отправить ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }