<template>
  <div>
    <b-row cols="2" class="regulation-table">
      <b-col>
        <b-row cols="1" class="rule-column">
          <b-col>
            Разрешено
          </b-col>

          <b-col v-for="(item, index) in allowed" :key="index">
            <div class="dot"></div>
            <span>{{item}}</span>
          </b-col>
          
        </b-row>
      </b-col>

      <b-col>
        <b-row cols="1" class="rule-column rule-column-warning" >
          <b-col>
            Запрещено
          </b-col>

          <b-col v-for="(item, index) in forbidden" :key="index">
            <div class="dot"></div>
            <span>{{item}}</span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    forbidden: Array, 
    allowed: Array
  },
};
</script>

<style lang="scss" scoped>
.regulation-table {
  padding: 0 16px;
  .rule-column > div {
    align-items: center;
    display: flex;
    padding: 10px 0px;
    border-bottom: 1px solid #cbd2e0;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .rule-column {
    .col {
      word-break: break-word;
    }
    padding: 16px;
    div:first-child {
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
    div:last-child {
      border: none;
    }
    .dot {
      width: 4px;
      min-width: 4px;
      height: 4px;
      background-color: #000;
      border-radius: 50px;
      margin-right: 6px;
    }
  }
  .rule-column-warning {
    background-color: #ffe8e1;
    border-radius: 24px;
  }
}

.dark-layout .regulation-table {
  .rule-column {
    color: #FFF;
    .dot {
      background-color: #FFF;
    }
  }
  .rule-column-warning {
    color: #000;
    .dot {
      background-color: #000;
    }
  }
}
</style>
