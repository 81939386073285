<template> 
  <div>
    <div style="margin-bottom: 28px">
      <b-card-sub-title sub-title-text-variant="primary">
        Пример 1
      </b-card-sub-title>

      <b-card-text>
        Размещайте объявления на «Авито», «Юле», барахолках и досках объявлений, где есть раздел «Работа».Тут все просто: больше объявлений на разных площадках – больше откликов и заявок.
      </b-card-text>
    </div>

    <div style="margin-bottom: 28px">
      <b-card-sub-title sub-title-text-variant="primary" >
        Пример 3
      </b-card-sub-title>

      <b-card-text>
        После того, как натренировались с бесплатными ресурсами, переходите к платным. Воспользуйтесь услугами агрегаторов вакансий. Например, на https://работа-вакансии.рф размещение одного объявления на 45 сайтах по поиску работы обойдется в <b>500-1000 руб.</b> в зависимости от срока.
      </b-card-text>
    </div>

    <div style="margin-bottom: 28px">
      <b-card-sub-title sub-title-text-variant="primary">
        Пример 3
      </b-card-sub-title>

      <b-card-text>
        В социальных сетях бесчисленное множество сообществ по трудоустройству, только в ВК их больше сотни по запросу «Работа в название_города». Размещайте там прямую ссылку для клиента с коротким описанием. Условия публикации вакансий везде разные, где-то можно сделать это бесплатно или за символическую сумму. Все зависит от популярности конкретного сообщества.
      </b-card-text>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    // item: Object,
  },
};
</script>

<style>

</style>
