<template>
  <div class="claim-content">

    <b-card-body v-if="!link" style="padding: 0">
      <b-card-title>Укажите данные нового клиента</b-card-title>
      
      <validation-observer ref="client" >
        <b-form class="client-form">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="Фамилия"
                label-for="last_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Фамилия"
                  rules="required"
                >
                  <b-form-input
                    placeholder="Введите фамилию"
                    v-model="form.last_name"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

           <b-col cols="12">
              <b-form-group
                label="Имя"
                label-for="first_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Имя"
                  rules="required"
                >
                  <b-form-input
                    placeholder="Введите имя"
                    v-model="form.first_name"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Отчество"
                label-for="middle_name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Отчество"
                  rules="required"
                >
                  <b-form-input
                    placeholder="Введите отчество"
                    v-model="form.middle_name"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

             <b-col cols="12">
              <b-form-group
                label="Телефон"
                label-for="phone_number"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Телефон"
                  rules="required"
                >
                  <b-form-input
                    v-model="form.phone_number"
                    name="phone_number"
                    :placeholder="'+7 (###) ### ##-##'"
                    v-mask="'+7 (###) ### ##-##'"
                  /> 

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" class="d-flex">
              <b-button
                type="submit"
                @click.prevent="submit"
                class="ml-auto w-100"
                pill
                variant="primary"
                :disabled="is_loading"
                style="font-size: 16px; border-radius: 16px;"
              >
                Далее
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>

    </b-card-body>
    
    <b-card-body style="padding: 0" v-else>
      <alert-card-view
        text="Компания приступит к рассмотрению заявки после того, как вы полностью заполните ее на сайте партнера."
        :warning="true"
      />
      <b-row>
        <b-col cols="12" class="d-flex">
          <b-button
            :href="link"
            target="_blank"
            class="ml-auto w-100"
            pill
            variant="primary"
            style="font-size: 16px; border-radius: 16px;"
          >
            Завершение оформления на сайте партнера
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import alertCardView from "@/components/AlertCard";


export default {
  data() {
    return {
      is_loading: false,
      form: {
        first_name: '',
        last_name: '',
        middle_name: '',
        phone_number: ''
      },
      link: null
    };
  },
  props: {
    offer: Number
  },
  methods: {
    submit() {
      this.$refs.client.validate().then(success => {
        if (!success) return;
        this.is_loading = true;
        this.form.offer = this.offer;
        this.$request.post("orders.create", this.form ).then( rsp => {
          this.link = rsp.link;
          this.is_loading = false;
        });
      });
    },
  },

  computed: {},

  mounted() {},

  watch: {
   
  },

  components: {
    ValidationProvider,
    ValidationObserver,
    alertCardView,

  },
};
</script>

<style lang="scss" scoped>

</style>
