import { render, staticRenderFns } from "./AdvicesCard.vue?vue&type=template&id=250f2cda"
import script from "./AdvicesCard.vue?vue&type=script&lang=js"
export * from "./AdvicesCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports