<template>
  <div class="share-content">
    <div ref="container" class="share-content__link mb-1 mt-1">
      {{link}}
    </div>

    <transition name="zoom-fade" mode="out-in">

    <div v-if="is_show_settings">

      <b-card-title>Настройка меток для трекинга трафика</b-card-title>

      <b-card-text> Добавьте utm-метки (латиница, без пробелов и спецсимволов) в персональную ссылку, чтобы отслеживать источники переходов и эффективность рекламы. </b-card-text>

      <b-form-group>
        <validation-provider
          #default="{ errors }"
          name="w_utm1"
          rules=""
        >
          <!-- <span class="text-muted" style="font-size: 12px;">u-utm1</span> -->
          <b-form-input
            placeholder="w_utm1"
            v-model="w_utm1"
          />
          <small class="text-danger font-small-1">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group v-if="w_utm1.length > 0">
        <validation-provider
          #default="{ errors }"
          name="w_utm2"
          rules=""
        >
          <!-- <span class="text-muted" style="font-size: 12px;">u-utm2</span> -->
          <b-form-input
            placeholder="w_utm2"
            v-model="w_utm2"
          />
          <small class="text-danger font-small-1">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

    </div>
    </transition>

    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center" style="cursor: pointer" @click="copyUrl"> 
        <div ref="container">
          <span style="color: var(--link-primary)">
              Скопировать ссылку
          </span>
        </div>
        <feather-icon icon="CopyIcon" style="margin-left: 4px; color: var(--link-primary)" />
      </div>

      <div @click="toggleSettingsUtm" class="d-flex align-items-center" style="cursor: pointer"> 
        <span>
          Настроить метки
        </span>
        <feather-icon v-if="is_show_settings" icon="ChevronUpIcon" style="margin-left: 4px;" />
        <feather-icon v-else icon="ChevronDownIcon" style="margin-left: 4px;" />
      </div>
    </div>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  data() {
    return {
      is_loading: false,
      w_utm1: '',
      w_utm2: '',

      is_show_settings: false,
    };
  },
  props: {
    link:  {
      type:String,
      default: ''
    }
  },
  methods: {
    copyUrl( args ) {            
        let container = this.$refs.container;
        this.$copyText( this.link, container );
        
        this.$toast({
            component: ToastificationContent,
            props: {
                title: this.$t('toasts.titles.copy'),
              text: 'Ссылка успешно скопирована в буфер обмена.',
              icon: 'CheckCircleIcon',
              variant: 'success',
            }
        });  
    },
    toggleSettingsUtm() {
      this.is_show_settings = !this.is_show_settings;
    },
    genereteUrl() {
      this.clearUtmWithUrl();

      if (this.w_utm1.length > 0) {
        if (!this.link.includes('?')) this.link += '?';
        this.link += '&w_utm1=' + this.w_utm1;
      }

      if (this.w_utm2.length > 0) {
        this.link += '&w_utm2=' + this.w_utm2;
      }

    },
    clearUtmWithUrl() {
      let i = this.link.indexOf('&w_utm1');
      if (i > -1) this.link = this.link.substring(0, i);
      if (this.link.slice(-1) == '?') this.link = this.link.substring(0, this.link.length - 1);
    }
  },

  computed: {},

  mounted() {
  
  },

  watch: {
    "w_utm1": {
      handler(bef) {
        if (bef.length == 0 && this.w_utm2.length > 0) {
          this.w_utm1 = this.w_utm2
          this.w_utm2 = '';
        }

        this.genereteUrl();
      }
    },
    "w_utm2": {
      handler(bef) {
        this.genereteUrl();
      }
    }
  },

  components: {
    ValidationProvider,
    ValidationObserver
  }
};
</script>

<style lang="scss" scoped>
  .share-content {
    &__link {
      padding: 0.438rem 1rem;
      border: 1px solid #d8d6de;
      border-radius: 8px;
      word-break: break-word;
    } 
  }
</style>
