import { render, staticRenderFns } from "./claim.vue?vue&type=template&id=c4cfaed6&scoped=true"
import script from "./claim.vue?vue&type=script&lang=js"
export * from "./claim.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4cfaed6",
  null
  
)

export default component.exports