<template>
  <div>
    <b-card no-body class="detail-offer-card">
      <b-card-header style="padding: 0px">
        <div
          class="attachment d-flex align-items-end"
          :style="{
            margin: '0px',
            width: '100%',
            padding: '8px',
            '--thumb': `url(${
              offer.medias.length > 1
                ? offer.medias[1].urls.original
                : offer.medias[0].urls.original
            })`,
            '--placeholder': `url(${
              offer.medias.length > 1
                ? offer.medias[1].urls.placeholder
                : offer.medias[0].urls.placeholder
            })`,
            borderRadius: '24px',
          }"
        >
          <div class="company-logo" :style="mediaViewStyleLogoCard()"></div>

          <div class="offer-markers-block">
            <!-- <div class="offer-marker" style="background-color: #1e2041">
              <b-img src="@/assets/images/icons/icon-fire.svg" alt="logo" />
            </div>
            <div class="offer-marker" style="background-color: #ef8961">
              <b-img src="@/assets/images/icons/icon-diamond.svg" alt="logo" />
            </div> -->
          </div>
        </div>
      </b-card-header>

      <b-card-body class="d-flex" style="padding: 32px">
        <div class="d-flex flex-column" style="flex: 1; padding-right: 17px">
          <ul class="list-unstyled">
            <li style="display: inline-block; margin-right: 4px">
              <b-badge
                variant="secondary"
                style="
                  border-radius: 50px;
                  padding: 4px 8px 6px 8px;
                  font-weight: 400;
                "
              >
                {{ offer.has_category.name }}
              </b-badge>
            </li>
          </ul>

          <p class="detail-offer-card-title">{{ offer.title }}</p>

          <b-button-group class="offer-section-tabs" style="margin-bottom: 20px">
            <template v-for="tab in tabs">
              <b-button
                v-if="isShowTab(tab)"
                @click="scrollToElement(tab.value)"
                :variant="
                  filter.tab === tab.value
                    ? 'flat-active-link'
                    : 'flat-inactive-link'
                "
                size="sm"
                style="height: 36px"
                >{{ tab.display }}</b-button
              >
            </template>
          </b-button-group>

          <section id="description" class="offer-card-section">
            <b-card-title> Описание </b-card-title>
            <div
              class="offer-custom-block"
              v-html="offer.description_content"
            />
            <alert-card-view
              text=" Для получения детальной информации, которой нет в описании к офферу —
                по требованиям к кандидатам, условиям работы и обязанностям - оставьте
                на себя тестовую заявку в качестве кандидата через сайт (пройдите по
                ссылке для клиента). Партнер свяжется с вами и вы уточните все
                интересующие вас вопросы"
            />
            <alert-card-view
              text="Для публикации рекламы вы можете использовать свои баннеры/креативы и
                сами придумать текст объявления. Главное условие - ваш текст не должен
                противоречить прописанным в оффере требованиям к кандидату/критериям
                валидности."
            />
          </section>

          <section id="insurance" class="offer-card-section">
            <b-card-title> Как оформить </b-card-title>
            <list-card-view
              variant="bubble"
              title="Способ №1 – ручное оформление заявки на 2work"
              :items="bubbleList"
            >
              <template>
                <b-button
                  v-if="isOpenAccess"
                  @click="modalsVisibility.claim = true"
                  style="width: 200px"
                  variant="primary"
                >
                  Оформить заявку
                </b-button>
                <b-button
                  v-else-if="isAccessShowApplicationFrom"
                  @click="modalsVisibility.access_form = true"
                  style="width: 200px"
                  variant="primary"
                >
                  Запросить доступ
                </b-button>
              </template>
            </list-card-view>

            <list-card-view
              variant="bubble"
              title="Способ №2"
              :items="bubbleList2"
            >
              <template>
                <b-button
                  v-if="isOpenAccess"
                  @click="modalsVisibility.share = true"
                  style="width: 200px"
                  variant="secondary"
                >
                  <img
                    alt="share"
                    class="share"
                    src="@/assets/images/icons/icon-share-purple.svg"
                  />
                </b-button>
              </template>
            </list-card-view>
            <alert-card-view
              text="После заполнения анкеты с кандидатом свяжется менеджер, ответит
              на интересующиевопросы и пригласит на собеседование"
              :warning="true"
            />
          </section>

          <section id="traffics" class="offer-card-section">
            <b-card-title> Типы трафика </b-card-title>
            <alert-card-view
              text="При использовании запрещенных типов трафика к вам могут быть применены
              санкции: начиная с отключения от оффера до обнуления выплат. Просим вас
              быть внимательными"
              :warning="true"
            />
            <regulation-table-view
              :allowed="offer.type_traffic_allowed"
              :forbidden="offer.type_traffic_forbidden"
            />
          </section>

          <section v-if="offer.advice_content" id="tips" class="offer-card-section">
            <b-card-title> Советы по поиску кандидатов </b-card-title>
            <div
              class="offer-custom-block"
              v-html="offer.advice_content"
            />
          </section>

          <section id="geo" class="offer-card-section">
            <b-card-title> Гео </b-card-title>
              <ul class="list-unstyled">
                <li
                  style="display: inline-block; margin-right: 4px; margin-bottom: 4px"
                  v-for="(city, index) in getCitiesOnFilter"
                  :key="index"
                >
                  <b-badge
                    variant="secondary"
                    style="
                      border-radius: 50px;
                      padding: 4px 8px 6px 8px;
                      font-weight: 400;
                    "
                  >
                    {{ city.name }}
                  </b-badge>
                </li>
                <li
                  v-if="offer.cities.length == 0"
                  style="display: inline-block; margin-right: 4px"
                >
                  <b-badge
                    variant="secondary"
                    style="
                      border-radius: 50px;
                      padding: 4px 8px 6px 8px;
                      font-weight: 400;
                    "
                  >
                    Вся РФ
                  </b-badge>
                </li>
              </ul>
            <b-button v-if="isShowBtnMoreGeo" @click="showAllCities" >
              Показать все города
            </b-button>
          </section>
        </div>

        <b-card no-body class="sticky-offer-card">
          <div class="d-flex flex-column">
            <div class="d-flex flex-column" style="margin-bottom: 16px">
              <small class="text-muted">Доход</small>
              <span class="fs-28 font-weight-bolder">
                {{ offer.goals && offer.goals.length > 1 ? 'до ' : '' }}
                {{
                  !offer.is_percentage_income
                    ? offer.amount.formatMoney(" ", " ", " ") + " ₽"
                    : offer.amount + " %"
                }}
              </span>
            </div>
            <div class="d-flex flex-column" style="margin-bottom: 16px">
              <small class="text-muted">Срок подтверждения</small>
              <span class="fs-28"
                >до {{ offer.conformation_period }}
                {{
                  Utils.declOfNum(offer.conformation_period, [
                    "день",
                    "дня",
                    "дней",
                  ])
                }}</span
              >
            </div>
          </div>

          <b-button
            v-if="isOpenAccess"
            @click="modalsVisibility.claim = true"
            variant="primary"
            style="width: 100%; border-radius: 8px; margin-bottom: 20px"
          >
            Оформить заявку
          </b-button>

          <span
            v-else-if="
              !isOpenAccess &&
              offer.application_opening &&
              (offer.application_opening.status === 1 ||
                offer.application_opening.status === 2)
            "
            style="padding-top: 13px; padding-bottom: 15px; margin-bottom: 10px"
            class="text-muted"
          >
            Заявка на рассмотрении
          </span>

          <span
            v-else-if="
              !isOpenAccess &&
              offer.application_opening &&
              offer.application_opening.status === 0
            "
            style="padding-top: 13px; padding-bottom: 15px; margin-bottom: 10px"
            class="text-danger"
          >
            Доступ отклонен партнером
          </span>

          <b-button
            v-else-if="isAccessShowApplicationFrom"
            @click="modalsVisibility.access_form = true"
            variant="primary"
            style="width: 100%; border-radius: 8px; margin-bottom: 20px"
          >
            Запросить доступ
          </b-button>

          <div class="d-flex">
            <b-button
              v-if="!isFavorite"
              @click="likeUnlike"
              block
              variant="secondary"
              :style="!isOpenAccess ? 'margin:0' : ''"
            >
              <img
                alt="like"
                class="like"
                src="@/assets/images/icons/icon-like-outline.svg"
              />
            </b-button>

            <b-button
              v-else
              @click="likeUnlike"
              block
              variant="secondary"
              :style="!isOpenAccess ? 'margin:0' : ''"
            >
              <img
                alt="like"
                class="like"
                src="@/assets/images/icons/icon-like.svg"
              />
            </b-button>

            <b-button
              v-if="isOpenAccess"
              @click="modalsVisibility.share = true"
              block
              variant="secondary"
              style="margin: 0"
            >
              <img
                alt="share"
                class="share"
                src="@/assets/images/icons/icon-share-purple.svg"
              />
            </b-button>
          </div>
        </b-card>
      </b-card-body>
    </b-card>

    <b-modal
      id="share-offer"
      v-model="modalsVisibility.share"
      centered
      title="Скопируйте и отправьте ссылку "
      hide-footer
    >
      <share-modal-content :link="offer.share_link" />
    </b-modal>

    <b-modal
      id="claim-offer"
      v-model="modalsVisibility.claim"
      centered
      title="Оформление заявки"
      hide-footer
    >
      <claim-modal-content :offer="offer.id" />
    </b-modal>

    <b-modal
      id="access-form"
      v-model="modalsVisibility.access_form"
      centered
      title="Доступ к продукту"
      hide-footer
    >
      <access-form-modal-content
        :offer="offer.id"
        @afterSendApplicationOpening="afterSendApplicationOpening"
      />
    </b-modal>
  </div>
</template>

<script>
import alertCardView from "@/components/AlertCard";
import regulationTableView from "../components/RegulationTable";
import advicesCardView from "../components/AdvicesCard";
import listCardView from "../components/ListCard";

import Utils from "@/modules/utils/";
import dimond from "@/assets/images/icons/icon-diamond.svg";

import ShareModalContent from "../modals/share";
import ClaimModalContent from "../modals/claim";
import AccessFormModalContent from "../modals/access.form";

import User from "@/modules/user";

export default {
  data() {
    return {
      User,
      Utils,
      dimond,
      isShowAllCities: false,
      modalsVisibility: {
        share: false,
        claim: false,
        access_form: false,
      },
      filter: {
        tab: "description",
      },
      tabs: [
        { value: "description", display: "Описание" },
        { value: "insurance", display: "Как оформить" },
        { value: "traffics", display: "Типы трафика" },
        { value: "tips", display: "Советы по поиску кандидатов" },
        { value: "geo", display: "ГЕО" }
      ],
      itemsList: [
        "Заявка создается в статусе «Переход» или «В обработке»;",
        "Если кандидат проходит по критериям валидности и подтверждает, что оставлял заявку, ее статус меняется на «Начислено» - то есть предварительно одобрена партнером после еженедельной промежуточной сверки*;",
        "Если кандидат проходит по критериям валидности и подтверждает, что оставлял заявку, ее статус меняется на «Начислено» - то есть предварительно одобрена партнером после еженедельной промежуточной сверки*;",
      ],
      itemsList2: [
        "Гражданство РФ, ЕАЭС и Украина (беженцы);",
        "М/Ж, 18-55 лет.",
      ],
      bubbleList: [
        "Нажмите кнопку «Оформить заявку», заполните ФИО кандидата и его телефон",
        "Далее нажмите «К следующему шагу» → «Завершите оформление на сайте партнера»",
        "На странице партнера заполните анкету с данными кандидата и нажмите «Отправить»",
        "Заявка уходит в обработку",
      ],
      bubbleList2: [
        "Нажмите кнопку «Ссылка для клиента», скопируйте в открывшемся окне ссылку и отправьте ее клиенту",
        "Клиент самостоятельно оформляет заявку по этой ссылке",
      ],
    };
  },
  props: {
    offer: Object,
  },
  components: {
    advicesCardView,
    regulationTableView,
    alertCardView,
    listCardView,
    ShareModalContent,
    ClaimModalContent,
    AccessFormModalContent,
  },
  methods: {
    mediaViewStyleLogoCard() {
      return {
        position: "absolute",
        height: "40px",
        width: "94px",
        marginLeft: "20px",
        backgroundImage: `url(${
          this.offer.has_company.medias[0].thumb ||
          this.offer.has_company.medias[0].urls.thumb
        })`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        borderRadius: "12px",
        border: "1px solid #CBD2E0",
      };
    },
    closeModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });
    },
    showModal(action) {
      Object.keys(this.modalsVisibility).forEach((key) => {
        this.modalsVisibility[key] = false;
      });

      if (this.modalsVisibility.hasOwnProperty(action)) {
        this.modalsVisibility[action] = true;
      }
    },
    scrollToElement(elID) {
      this.filter.tab = elID;

      return this.$smoothScroll({
        scrollTo: document.getElementById(elID),
        updateHistory: false,
        hash: `#${elID}`, // required if updateHistory is true
      });
    },
    onScroll(e) {
      if (window.pageYOffset < 300) this.filter.tab = "description";
    },
    likeUnlike() {
      if (this.isFavorite) User.unLikeOffer(this.offer.id);
      else User.likeOffer(this.offer.id);
    },
    afterSendApplicationOpening() {
      this.offer.application_opening = {
        status: 1,
      };
      this.closeModal();
    },
    isShowTab(tab) {
      if (tab.value == 'tips' && !this.offer.advice_content) return false;
      return true;
    },
    showAllCities() {
      this.isShowAllCities = true;
    }
  },
  computed: {
    isFavorite() {
      let offer = User.self.favorites.find((l) => l.offer === this.offer.id);
      if (offer) return true;
      else return false;
    },
    isOpenAccess() {
      if (this.offer.state_access === "open") return true;
      if (
        this.offer.state_access === "order" &&
        this.offer.application_opening &&
        this.offer.application_opening.status === "3"
      ) return true;

      return false;
    },
    isAccessShowApplicationFrom() {
      if (!this.isOpenAccess && !this.offer.application_opening) return true;

      return false;
    },
    isShowBtnMoreGeo() {
      if (!this.isShowAllCities && this.offer.cities.length > 50) return true;
      return false
    },
    getCitiesOnFilter() {
      if (this.isShowAllCities) {
        return this.offer.cities
      } else {
        return this.offer.cities.slice(0, 50);  
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  mounted() {
  },
};
</script>
<style lang="scss" scoped>
.sticky-offer-card {
  width: 308px;
  height: 309px;
  background-color: rgba(126, 118, 227, 0.1);
  border-radius: 24px !important;
  padding: 32px 20px;
  position: sticky;
  top: 22px;
}
</style>
<style lang="scss">
.offer-section-tabs {
  .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.offer-tabs {
  .btn-inactive {
    border-bottom: 1px solid #787e8e;
  }
}
.detail-offer-card {
  .attachment {
    // 1200x220
    height: 211px;

    // 1200x320
    // height: 306px;
  }
  .company-logo {
    top: 180px;

    // 1200x320
    // top: 280px;
  }
  @media screen and (max-width: 1440px) {
    .attachment {
      height: 190px;
    
      // 1200x320
      // height: 260px;

      background-size: cover, cover;
    }
    .company-logo {
      top: 165px;

    // 1200x320
      // top: 230px;
    }
  }
  .offer-card-section {
    margin-bottom: 46px;
    .card-title {
      padding-top: 10px;
    }
  }
  .offer-custom-block {
    margin-bottom: 28px;
  }
  .detail-offer-card-title {
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
  }
  .card-title {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
  }
  .card-subtitle {
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 16px;
  }
  .offer-markers-block {
    position: absolute;
    top: 0px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    max-height: 100px;
    flex-wrap: nowrap;
    .offer-marker {
      padding: 12px 0px;
      align-items: center;
      display: flex;
      justify-content: center;
      width: 48px;
      height: 48px;
      border-radius: 50px;
      margin-left: 20px;
      margin-bottom: 4px;
    }
  }
}
</style>
